export interface DepositRate {
  term: {
    type: 'months' | 'days'; // Same
    value: number | string;
  };
  minimumDeposit: {
    value: number;
  };
  maturity: {
    value: number;
  };
  annually?: {
    value: number;
  };
  semiAnnually?: {
    value: number;
  };
  monthly?: {
    value: number;
  };
}

// Follow the format - MM/DD/YYYY
export const effectiveDate = new Date('09/18/2024');

export const depositRates: DepositRate[] = [
  {
    term: {
      type: 'months',
      value: 12,
    },
    minimumDeposit: {
      value: 5000,
    },
    maturity: {
      value: 4.12,
    },
    annually: {
      value: 4.12,
    },
    semiAnnually: {
      value: 4.02,
    },
    monthly: {
      value: 3.92,
    },
  },
  {
    term: {
      type: 'months',
      value: 24,
    },
    minimumDeposit: {
      value: 5000,
    },
    maturity: {
      value: 3.92,
    },
    annually: {
      value: 3.92,
    },
    semiAnnually: {
      value: 3.82,
    },
    monthly: {
      value: 3.72,
    },
  },
  {
    term: {
      type: 'months',
      value: 36,
    },
    minimumDeposit: {
      value: 5000,
    },
    maturity: {
      value: 3.85,
    },
    annually: {
      value: 3.85,
    },
    semiAnnually: {
      value: 3.75,
    },
    monthly: {
      value: 3.65,
    },
  },
  {
    term: {
      type: 'months',
      value: 48,
    },
    minimumDeposit: {
      value: 5000,
    },
    maturity: {
      value: 3.68,
    },
    annually: {
      value: 3.68,
    },
    semiAnnually: {
      value: 3.58,
    },
    monthly: {
      value: 3.48,
    },
  },
  {
    term: {
      type: 'months',
      value: 60,
    },
    minimumDeposit: {
      value: 5000,
    },
    maturity: {
      value: 3.73,
    },
    annually: {
      value: 3.73,
    },
    semiAnnually: {
      value: 3.63,
    },
    monthly: {
      value: 3.53,
    },
  },
  {
    term: {
      type: 'days',
      value: '30-59',
    },
    minimumDeposit: {
      value: 10000,
    },
    maturity: {
      value: 0.1,
    },
  },
  {
    term: {
      type: 'days',
      value: '60-89',
    },
    minimumDeposit: {
      value: 10000,
    },
    maturity: {
      value: 0.15,
    },
  },
  {
    term: {
      type: 'days',
      value: '90-179',
    },
    minimumDeposit: {
      value: 10000,
    },
    maturity: {
      value: 1.1,
    },
  },
  {
    term: {
      type: 'days',
      value: '180-269',
    },
    minimumDeposit: {
      value: 10000,
    },
    maturity: {
      value: 2.01,
    },
  },
  {
    term: {
      type: 'days',
      value: '270-364',
    },
    minimumDeposit: {
      value: 10000,
    },
    maturity: {
      value: 2.36,
    },
  },
];
